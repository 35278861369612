.notFoundContainer {
  min-height: calc(100vh - 310px);
  max-width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    height: 62vh;

    max-width: 90vw;
  }

  h1 {
    font-size: 2.1875rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.5625rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
